import React, { useEffect, useState } from "react";
import FrontVideo from "../../components/homevideo";
import HeaderTitle from "../../components/headerTitle";
import ClientExperiences from "../../components/home/client-experiences";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import WhatWeOffer from "../../components/CommonSections/WhatWeOffer";
import Advantage from "../../components/CommonSections/Advantage";
import PageLoader from "../../components/home/pageload";
import MetaTag from "../../components/CommonSections/MetaTag";
import ImgSmartCity from "../../assest/images/IshanSmartCity.png";
import ImgDedicatedLeasedLine from "../../assest/images/dedicated-leased-line-ic.svg";
import ImgOfficeInternet from "../../assest/images/office-internet-ic.svg";
import ImgIshanLeasedLine from "../../assest/images/ishan-leased-line-ic.svg";
import PlansPopup from "../contacts/plans-popup";
import ImgSec1 from "../../assest/images/apple-sec-1.png";
import ImgSec2 from "../../assest/images/apple-sec-2.png";
import AppleIc from "../../assest/images/apple-ic.png";

import { Link } from "react-router-dom";
import SubServiceSchema from "../../components/CommonSections/subServicesSchema";
import ColocationPopup from "../../components/colocation-popup";
import Accordion from '../../components/accordion';
import InternetServiceSec from "../../components/interner-service";
import {
     Input,
     FormFeedback,
     Form, 
     Modal,
     ModalBody,
   } from "reactstrap";

export default function TestPage() {
     const handleClick = () => {
          window.scrollTo(0, 0);
     }

     const [InternetService, setInternetService] = useState(null);
     const [settingResponse, setSettingResponse] = useState(null);

     useEffect(() => {
          const requestData = {  
               "service_tag": "apple_services"
          };

          axios.post(`${API_BASE_URL}/api/smartcity/getsmartCity`, requestData).then((response) => {
               setInternetService(response);

               console.log('response',response);
               const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;
               const InternetServicesSettingsData = generalSettingsData.find((item) => item.tag === "smart_city_advantage") || null;
               const InternetServicesSettingsResponse = JSON.parse(InternetServicesSettingsData?.response || "{}");

               InternetServicesSettingsResponse.advantages = InternetServicesSettingsResponse;
               setSettingResponse(InternetServicesSettingsResponse);
          }).catch((error) => { 
               <PageLoader />
          })
     }, [])

     const [modal, setModal] = useState(false);
     const [colocationData, setcolocationData] = useState(null);
     const toggle = () => {
       if (modal) {
         setModal(false);
       } else {
         setModal(true);
       }
     };
   
   
     const openModal = (colocationData) => {
       setModal(true);
       setcolocationData(colocationData);
     };   


     const [getPlanModal, setPlanModal] = useState(false);
     const [planData, setPlanData] = useState(null);
     const planToggle = () => {
       if (getPlanModal) {
          setPlanModal(false);
       } else {
          setPlanModal(true);
       }
     };
    
   
     const openPlanModal = (planData) => {
          setPlanModal(true);
          setPlanModal(planData);
     };
   

     const [getInTuchPlanModal, setInTuchPlanModal] = useState(false);
     const planGetIntuchToggle = () => {
       if (getInTuchPlanModal) {
          setInTuchPlanModal(false);
       } else {
          setInTuchPlanModal(true);
       }
     };
    
   
     const openGetInTuchPlanModal = () => {
          setInTuchPlanModal(true);
     };
   

     return (

          <>
               {InternetService === null ? (
                    <PageLoader />
               ) : (
                    <>
                    <SubServiceSchema title={InternetService?.data?.data?.smart_city?.smart_city_banner_data
                              [0].title} url='https://ishantechnologies.com/systems-integration/smart-city-services' parentTitle='System Integration' parentUrl='https://ishantechnologies.com/systems-integration' />
                    <MetaTag data={InternetService} pageKey="smart_city"/>
                         <div 
                              className={`video hero-banner internet-banner ${
                              !InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                                                      [0]?.banner_title &&!InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                                                      [0]?.banner_img_desc 
                                   ? 'no-content' 
                                   : ''
                              }`}
                              >
                              <FrontVideo homeData={InternetService} pageKey="smart_city" />

                              <div className="banner-content">
                                   <div className="content-blk">
                                             {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.banner_title && (
                                             <h2 className='home-heading'>
                                                  {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0].banner_title}
                                             </h2>
                                             )}

                                             {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.banner_img_desc && (
                                             <p className="para">
                                                  {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0].banner_img_desc}
                                             </p>
                                             )}

                                             {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.button_img_name && (
                                             <a href={InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0].button_url}>
                                                  <button className="button hero-btn">
                                                  {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.button_img_name}
                                                  </button>
                                             </a>
                                             )}
                                   </div>
                                   <div className="form-blk">
                                        <InternetServiceSec homeData={InternetService} pageName="InternetLeaseLine" />
                                   </div>
                                   
                              </div>  
                         </div>

                         <div className="empowering section-space spin-wrap">
                              <div className="spin-round-wrap">
                                   <div className="spin-round color-navy-blue">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>

                              <div className="container apple-page">
                                   <div className="empowering-inner">
                                        <div className="empowering-right primary-title" dangerouslySetInnerHTML={{ __html: InternetService?.data?.data?.smart_city?.smart_city_information_data[0]?.description }}>
                                   </div>
                                   </div>
                                   <div class="row mt-5">
                                        <div class="col-md-6 shadow-sect">
                                             <img src={ImgSec1} className="img-fluid" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                             <h2>Enable Enterprise Productivity with Apple <span className="text-highlight">DEVICES</span> </h2>
                                             
                                             <p className="text-primary mb-2">Flexible, Scalable, and Hassle-Free</p>
                                             <p className=" mb-2">
                                             Experience high-performance Apple devices, including MacBooks and iPhones, designed to drive productivity, efficiency, and growth for your business.
                                             </p>
                                             <ul>
                                                  <li>
                                                   <span className="fw-bold">Access to the Latest MacBook Models:</span>  Always work with cutting-edge technology.
                                                  </li>
                                                  <li>
                                                   <span className="fw-bold">Capex to Opex Transformation:</span>  Convert capital expenses into operational expenses for better financial flexibility.
                                                  </li>
                                                  <li>
                                                   <span className="fw-bold">Managed updates and hardware support </span>  for seamless operations.
                                                  </li>
                                                  <li>
                                                   <span className="fw-bold"> Flexible terms designed </span>to grow alongside your business needs with scalable solutions 
                                                  </li>
                                                  
                                                  
                                             </ul>
                                             <a href="" className="fw-bold">
                                                   Know More About Apple Devices
                                             </a>
                                        </div>
                                   </div>
                                   <div class="row mt-5 pt-5 flex-row-reverse">
                                        <div class="col-md-6">
                                             <img src={ImgSec2} className="img-fluid" alt="" />
                                        </div>
                                        <div class="col-md-6 shadow-sect">
                                             <h2>Seamless <span className="text-highlight"> Device Management </span>with Apple Business Manager </h2>
                                             
 
                                             <p className="text-primary mb-2">
                                             Effortless Control Over Your Apple Ecosystem Streamline IT operations with Apple Business Manager. Empower your team to securely manage and configure Apple devices across your organization, ensuring seamless integration, compliance, and productivity.
                                             </p>
                                             <ul>
                                             <li>
                                                   <span className="fw-bold"> Centralized Management:</span>  Configure, monitor, and control all Apple devices remotely
                                                  </li>
                                                  <li>
                                                   <span className="fw-bold">Enhanced Security:</span>  Enforce policies, enable encryption, and perform remote wipes to protect sensitive data.
                                                  </li>
                                                  <li>
                                                   <span className="fw-bold"> Seamless Updates: </span>  Deploy updates and apps effortlessly without interrupting workflows.
                                                  </li>
                                                  <li>
                                                   <span className="fw-bold">Take the Next Step: </span>Discover how <span className="fw-bold">Apple</span> can transform your IT infrastructure and drive efficiency. 
                                                  </li>
                                             </ul>
                                             <a href="" className="fw-bold">
                                                  Contact us to know more
                                             </a>
                                        </div>
                                   </div>
                              </div>

                              <div className="spin-round-wrap">
                                   <div className="spin-round">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>
                         </div>

          
                         <div className="client-experiences section-space">
                              <ClientExperiences homeData={InternetService} pageKey="smart_city" tag="smart_city" />
                         </div>
                         
                         <div className="supercharge supercharge-left">
                              <div className="container">
                                   <h3 className="supercharge-heading" style={{marginBottom:'2px', textAlign:'center'}}>{InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.title}</h3>

                                   <div className="supercharge-btns"  style={{textAlign:'center'}}>
                                        {InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_1 &&
                                             <Link to={InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_url_1}>
                                                  <button className="getintouch"  onClick={handleClick}>{InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_1?.toUpperCase()}</button>
                                             </Link>
                                        }
                                       
                                        {InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_2 &&
                                             <Link to={InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_url_2} target="_blank">
                                                  <button className="getintouch" onClick={handleClick}>{InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_2?.toUpperCase()}</button>
                                             </Link>
                                        }
                                   </div>
                              </div>
                         </div>
                         
                         <Modal isOpen={modal} toggle={toggle}>
                              <ModalBody>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); toggle(); }}>×</Link>
                              <ColocationPopup colocationData={colocationData} pageName="InternetLeaseLine" />
                              </ModalBody>
                         </Modal>

                         <Modal isOpen={getPlanModal} toggle={planToggle}>
                              <ModalBody>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); planToggle(); }}>×</Link>
                                   <ColocationPopup colocationData={colocationData} planDetails={getPlanModal} pageName="InternetLeaseLine" />
                              </ModalBody>
                         </Modal>

                         <Modal isOpen={getInTuchPlanModal} toggle={getInTuchPlanModal}>
                              <ModalBody>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); planGetIntuchToggle(); }}>×</Link>
                                   <div className="Mobile_getin_tuch">
                                      <InternetServiceSec homeData={InternetService} pageName="InternetLeaseLine" />
                                   </div>
                              </ModalBody>
                         </Modal>

                         <a href="javascript:void(0)" className="enquire-now-side-btn" onClick={() => openGetInTuchPlanModal()}>GET IN TOUCH</a>

                    </>
               )} 
          </>
     );
}